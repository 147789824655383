import React from "react"
import Layout from "../components/layout"
import { 
  Container,
  Row,
  Col,
  Card,
  Form,
  Button
} from "react-bootstrap"

const Contact = () => {
  return (
    <>
      <Layout>
        <section className="white-color gr-minh-100 d-flex align-items-center pt-5">
          <Container>
            <Row>
              <Col lg={{span:7}} className="d-inline-flex flex-column justify-content-start">
                <Card className="p-5 z-depth-0 blue-grey darken-3 black-text">
                  <h4 className="pb-5 uppercase orange-text">Contact Form</h4>
                  <Form method="post" netlify-honeypot="bot-field" data-netlify="true" name="Enterprise 360 contact form">
                    <input  type="hidden" name="bot-field" />
                    <Form.Group className="form-group">
                      <h6>Your Details</h6>
                      <Form.Control type="text" className="form-control" id="name" name="name" aria-describedby="contact" placeholder="Your name" required/>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Control type="email" className="form-control" name="email"  id="email" aria-describedby="contact" placeholder="Your email address" required/>
                      <small id="emailinfo" className="form-text">We'll never share your email with anyone else.</small>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <h6>Your Message</h6>
                      <Form.Control type="text" className="form-control" id="subject" name="subject"  aria-describedby="message" placeholder="Subject"/>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Control as='textarea' className="form-control" id="message" name="message"  aria-describedby="message" placeholder="Your message" required/>
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary deep-orange">Submit</Button>
                    <small id="messageinfo" className="form-text">We don't send marketing or sales emails.</small>
                  </Form>
                </Card>
              </Col>
              <Col lg={{span:5}} >
                <div className="p-5">
                  <h4 className="uppercase deep-orange-text">Phone</h4>
                  <h5>0800 804 7473</h5>
                  <p className="mb-5">(0800 - 1800 GMT)</p>
                  <h4 className="uppercase deep-orange-text">Email</h4>
                  <a href="mailto:info@greyridge.com" className=""><button type="button" className="email-us-button deep-orange btn btn-circle btn-xl mb-5"><i className="material-icons">email</i></button></a>
                  <h4 className="uppercase deep-orange-text">Write</h4>
                  <p className="card-text mb-5">GreyRidge Software, Cobalt Business Park, Cobalt Park Way, Newcastle-upon-Tyne, NE28 9NZ.</p>
                 </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default Contact